import React, { useContext, useEffect, useState } from 'react'
import { Await, NavLink } from 'react-router-dom'
import { ImExit } from 'react-icons/im'
import LoginContext from '../../Context/Logincontext'
import Swal from 'sweetalert2'
import { AiTwotoneDelete, AiFillEdit } from 'react-icons/ai';
import { urlApiServer } from '../../Config/Config'

const AddProductGeneric = (props) => {
    // let edit = props.edit
    let saveProduct = props.saveProduct
    let historyValidation = props.historyValidation
    let saveItemPrice = props.saveItemPrice
    let oldData = props.oldData;
    let oldIva = props.oldIva
    let toggleFullScreen = props.toggleFullScreen
    let ivaProdut = 0

    const [closeSession, setCloseSession] = useState(false)
    const { infoUser, setInfoUser } = useContext(LoginContext)
    const [deletoglee, setDeletogle] = useState(false)
    const [priceEnd, setPriceEnd] = useState()
    const [productName, setProductName] = useState('')
    const [productQuantity, setProductQuantity] = useState()
    const [productIva, setProductIva] = useState()
    const [unit, setUnit] = useState()
    const [clientGetTable, setClientGetTable] = useState([])

    const [productPrice, setProductPrice] = useState()
    // const [total, setTotal] = useState(0)
    const [reload, setReload] = useState(false)


    const [isFullScreen, setFullScreen] = useState(false);
    const [id, setId] = useState()

    const urlgetProducts = urlApiServer + "products"

    useEffect(() => {
    }, [reload, deletoglee])

    useEffect(() => {
        (async function () {

            const InicioHeaders = new Headers();
            InicioHeaders.append("Content-Type", "application/json");
            InicioHeaders.append("company", infoUser.company);

            const requesoption = {
                method: 'GET',
                headers: InicioHeaders,
                redirect: 'follow'
            }
            await fetch(urlgetProducts, requesoption)
                .then(response => response.json())
                .then(quotesResponse => {
                    setClientGetTable(quotesResponse);
                })
                .catch(error => {
                });
        }())

    }, [])

    useEffect(() => {
        clientGetTable.map((info, index) => {
            if (productName === info.producto) {
                // setIndexProduct(index)
                setProductName(info.producto)
                setProductPrice(info.precioitem)
                // setProductQuantity(info.cantidaditem)
                setProductQuantity(parseFloat(info.cantidaditem))
                setUnit(info.unit)
                setProductIva(info.iva)
                // setPhone(clientGetTable[index].telefono)
                // setClient(clientGetTable[index].cliente)
                // setEmail(clientGetTable[index].correo)
            }
        })
        // setSeller(infoUser.name)
    }, [productName])

    function addProduct() {
        const priceAndQuantity = productPrice * productQuantity
        let ivaProduct = priceAndQuantity * (parseFloat(productIva) / 100)
        const totalAndIva = priceAndQuantity + ivaProduct

        let data = {
            id: props.edit === 'Edit' ? props.historyValidation.Id : undefined,
            empresa: infoUser.companyType,
            producto: productName,
            precioitem: priceAndQuantity,
            cantidadItem: productQuantity,
            total: totalAndIva,
            Priceiva: totalAndIva,
            iva: productIva,
            // image_3d: props.request.image_3d,
            // image_2d: file2d,
            price: productPrice,
            unit: unit,
            // prePlans: props.prePlans,
            // Plans: props.Plans
        }
        saveProduct.push(data)
        const totalPrice = saveProduct.reduce((acc, item) => acc + item.total, 0);
        props.priceEndFunction(totalPrice)
        if (reload) {
            setReload(false)
        } else {
            setReload(true)
        }
    }


    function handleProductName(e) {
        const name = e.target.value
        if (name.length < 3001) {
            setProductName(name)
        } else {
            return Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'El maximo de caracteres para el detalle es de 3000',
            })
        }
    }

    function handleProductPrice(e) {
        const price = e.target.value
        if (price.length < 100) {
            setProductPrice(price)
        } else {
            return Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'El maximo de caracteres para el precio es de 100',
            })
        }
    }

    function handleProductIva(e) {
        const iva = e.target.value
        setProductIva(iva)
        // console.log("iva: ", e.target.value)
    }

    function handleUnit(e) {
        const unit = e.target.value
        setUnit(unit)
        // console.log("iva: ", e.target.value)
    }

    function handleProductQuantity(e) {
        const quantity = e.target.value
        setProductQuantity(quantity)
        // console.log("quantity: ", e.target.value)
    }

    // useEffect(() => {
    //     const parsedPriceEnd = +priceEnd
    //     const parsedPayment = +payment
    //     if (!isNaN(parsedPriceEnd) && !isNaN(parsedPayment)) {
    //         const matRest = parsedPriceEnd - parsedPayment
    //         setOperationMatRest(matRest)
    //     }
    // }, [priceEnd, payment])

    return (
        <form className='ContainerAddCuotes'>
            <div className='containerQuotes'>
                <div className='infoOneQuotes'>
                    <p className='texProducttitule'>PRODUCTOS</p>
                    <div className="containerTable">
                        <table id='test-table' className="minimal-table">
                            <tr>
                                <th>Productos</th>
                                <th>Precio Unidad</th>
                                <th>Cantidad</th>
                                <th>Total</th>
                                <th>Iva</th>
                                <th>Total + iva</th>
                                <th>Eliminar</th>
                                <th>Editar</th>
                            </tr>
                            {saveProduct.map((product, index) => {
                                async function deleteProduct1(index) {
                                    const id = saveProduct[index]
                                    Swal.fire({
                                        icon: 'question',
                                        text: 'estás seguro que deseas eliminar este item?',
                                        showCancelButton: true,
                                        confirmButtonText: 'Aceptar',
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            setDeletogle(true)
                                            saveItemPrice = []
                                            delete saveProduct[index]
                                            saveProduct = saveProduct.filter(function (el) {
                                                return el != null;
                                            });
                                            props.Reload(saveProduct)
                                        }
                                    })

                                }
                                async function Edit(index) {
                                    oldData = saveProduct[index].Priceiva
                                    oldIva = saveProduct[index].iva
                                    setId(index)
                                    setFullScreen(true)

                                    await Swal.fire({
                                        title: 'Editar',
                                        html:
                                            `
                                        <div>
                                            <p>Producto:</p>
                                            <input type="text" id="producto" value="`+ saveProduct[index].producto + `" class="swal2-input" placeholder="Producto">
                                        </div>
                                        <div>
                                            <p>Precio del producto:</p>
                                            <input type="text" id="precioitem" value="`+ (saveProduct[index].price) + `" class="swal2-input" placeholder="Precio del producto">
                                        </div>
                                        <div>
                                        <p>Cantidad:</p>
                                            <input type="text" id="cantidadItem" value="`+ saveProduct[index].cantidadItem + `" class="swal2-input" placeholder="Cantidad">
                                        </div>
                                        <div>
                                        <div>
                                            <p>Unidades:</p>
                                            <select id="mySelectUnit" class="ivaSelect">
                                            <option value="`+ saveProduct[index].unit + `" >Unidad: ` + saveProduct[index].unit + `</option>
                                            <option value="U">U (Unidad)</option>
                                            <option value="M">M (Metros)</option>
                                            <option value="M2">M2 (Metros Cuadrados)</option>
                                            <option value="C">C (Centimetros)</option>
                                            <option value="P">P (Pulgadas)</option>
                                            <option value="K">K (Kilogramos)</option>
                                            <option value="G">G (Gramos)</option>
                                            <option value="L">L (Libras)</option>
                                            <option value="LT">LT (Litros)</option>
                                            <option value="R">R (Radianes)</option>
                                            <option value="R">S (Segundos)</option>
                                            <option value="H">H (Horas)</option>
                                            <option value="Dia/s">Dias</option>
                                            <option value="Mes/es">Meses</option>
                                            <option value="Año/s">Años</option>
                                            </select>
                                        </div>

                                            <p>Iva:</p>
                                            <select id="mySelect" class="ivaSelect">
                                                <option value="`+ saveProduct[index].iva + `" >Iva: ` + saveProduct[index].iva + `</option>
                                                <option value="0">0%</option>
                                                <option value="5">5%</option>
                                                <option value="16">16%</option>
                                                <option value="19">19%</option>
                                            </select>
                                        </div>
                                        `,
                                        showCancelButton: true,
                                        focusConfirm: false,
                                        preConfirm: () => {
                                            return [
                                                /*0: */document.getElementById('producto').value,
                                                /*5: */document.getElementById('cantidadItem').value,
                                                /*6: */document.getElementById('precioitem').value,
                                                /*7: */document.getElementById('mySelectUnit').value,
                                                /*8: */document.getElementById('mySelect').value
                                            ]
                                        }
                                    }).then(response => {

                                        if (response.value) {
                                            const [producto, material, ancho, largo, calibre, cantidadItem, precioitem, mySelect] = response.value;
                                        }
                                        const priceAndQuantity = parseFloat(response.value[2]) * parseFloat(response.value[1])
                                        let ivaProduct = priceAndQuantity * (parseFloat(response.value[4]) / 100)
                                        const total = priceAndQuantity + ivaProduct
                                        // const id = historyValidation[index].Id


                                        if (response.value[0].length > 3001) {
                                            return Swal.fire({
                                                icon: 'error',
                                                title: 'Error',
                                                text: 'El maximo de caracteres para el detalle es de 3001',
                                            })
                                        }

                                        if (response.value[2].length > 100) {
                                            return Swal.fire({
                                                icon: 'error',
                                                title: 'Error',
                                                text: 'El maximo de caracteres para el precio es de 500',
                                            })
                                        }

                                        let obj = {
                                            "producto": response.value[0],
                                            "Id": null,
                                            'cantidadItem': response.value[1],
                                            "price": response.value[2],
                                            "precioitem": priceAndQuantity,
                                            "iva": response.value[4].toString(),//iva.toString(),
                                            'total': total,
                                            'unit': response.value[3],
                                            // 'empresa': "Generica",
                                        }
                                        let updateArraySaveProduct = saveProduct.slice();
                                        updateArraySaveProduct[index] = obj;
                                        saveProduct = updateArraySaveProduct;
                                        props.Reload(saveProduct)
                                    })

                                }
                                return (
                                    <tr>
                                        <td>{product.producto}</td>
                                        <td>${Math.round(product.price)}</td>
                                        <td>{product.cantidadItem}</td>
                                        <td>${Math.round(product.precioitem)}</td>
                                        <td>{product.iva}%</td>
                                        <td>${Math.round(product.total)}</td>
                                        <td><AiTwotoneDelete className='deleteButtonListProduct' onClick={() => { deleteProduct1(index) }} /></td>
                                        <td><AiFillEdit className='iconEdit' onClick={() => { Edit(index) }} /></td>

                                    </tr>

                                )
                            })}
                        </table>
                    </div>

                    <div className='containerFormulary'>
                        <div className="inputQuotesContainer">
                            <input
                                type="text"
                                autocomplete="off"
                                // name="CitizenshipCard"
                                // id="CitizenshipCard"
                                onChange={handleProductName}
                                className="inputquotes"
                                placeholder=" "
                                list="detalleList"
                                value={productName}
                            />
                            <label htmlFor="name">Detalle <em className='Obligatory'>*</em></label>
                            <datalist id="detalleList" value="clientSelect">
                                {
                                    clientGetTable
                                        .sort((a, b) => a.producto.localeCompare(b.producto)) // Ordenar alfabéticamente
                                        .map((product, index) => {
                                            return (
                                                <option key={index}>{product.producto}</option>
                                            )
                                        })
                                }
                            </datalist>
                        </div>

                        <div className="inputQuotesContainer">
                            <input
                                type="number"
                                autocomplete="off"
                                onWheel={(e) => e.target.blur()}
                                name="CitizenshipCard"
                                id="CitizenshipCard"
                                onChange={handleProductPrice}
                                className="inputquotes"
                                placeholder=" "
                                // list="drawfemails2"
                                value={productPrice}
                            />
                            <label htmlFor="name">Precio <em className='Obligatory'>*</em></label>
                        </div>

                        <div className="inputQuotesContainer">
                            <input
                                type="number"
                                autocomplete="off"
                                onWheel={(e) => e.target.blur()}
                                name="CitizenshipCard"
                                id="CitizenshipCard"
                                onChange={handleProductQuantity}
                                className="inputquotes"
                                placeholder=" "
                                // list="drawfemails2"
                                value={productQuantity}
                            />
                            <label htmlFor="name">Cantidad <em className='Obligatory'>*</em></label>
                        </div>

                        <div className="inputQuotesContainer">
                            <label htmlFor="name">Unidad:</label>
                            <select name="Type" id="Type" className='inputquotes' onChange={handleUnit} value={unit}>
                                <option value="">Unidad:</option>
                                <option value="U">U (Unidad)</option>
                                <option value="M">M (Metros)</option>
                                <option value="M2">M2 (Metros Cuadrados)</option>
                                <option value="C">C (Centimetros)</option>
                                <option value="P">P (Pulgadas)</option>
                                <option value="K">K (Kilogramos)</option>
                                <option value="G">G (Gramos)</option>
                                <option value="L">L (Libras)</option>
                                <option value="LT">LT (Litros)</option>
                                <option value="R">R (Radianes)</option>
                                <option value="R">S (Segundos)</option>
                                <option value="H">H (Horas)</option>
                                <option value="Dia/s">Dias</option>
                                <option value="Mes/es">Meses</option>
                                <option value="Año/s">Años</option>
                            </select>
                        </div>

                        <div className="inputQuotesContainer">
                            <label htmlFor="name">Iva: <em className='Obligatory'>*</em></label>
                            <select name="Type" id="Type" className='inputquotes' onChange={handleProductIva} value={productIva}>
                                <option value="Type">Iva:</option>
                                <option value="0">0%</option>
                                <option value="5">5%</option>
                                <option value="16">16%</option>
                                <option value="19">19%</option>
                            </select>
                        </div>

                    </div>
                    <div className='buttonContainerAddQuotes2'>
                        <div className="buttonQuotes neon_Effect" onClick={addProduct}><p>Agregar producto</p></div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default AddProductGeneric
