import { priceToletter } from "./functions/PriceToLetters";
import "./Contribution.css";
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom"
import jsPDF from "jspdf"
import { urlApiServer } from "../../../Config/Config";
import { FaSearchPlus, FaSearchMinus } from 'react-icons/fa'

function Contribution({ data, signature, handleShowBill }) {
  const [totalValue, setTotalValue] = useState(0);
  const [scale, setScale] = useState(1);

  const letterSpacing = { letterSpacing: '0.05px' };
  let urlSignature = null
  data.billPage ? urlSignature = urlApiServer + "bills/" + data.signature
    : urlSignature = urlApiServer + "Image/" + data.signature
  useEffect(() => {
    let total = 0;
    data.items.forEach((item) => {
      data.billPage ? total += parseFloat(item.cantidad) * parseFloat(item.valorUnit)
        : total += parseFloat(item.cantidadItem) * parseFloat(item.precio) * (1 + item.iva / 100);
    });
    data.billPage ? setTotalValue(total)
      : setTotalValue(total - (parseFloat(data.manure ? data.manure : 0)));
  }, [data.items]);

  useEffect(() => {
    // historyValidation = []
}, [scale])

  const disableBill = async () => {
    handleShowBill()
  }

  const createPDF = async () => {
    await setScale(1)
    // setReload(true)
    // setDisabledBottom(true)
    // setScale(1)
    var margin = 10
    var doc = new jsPDF('p', 'pt', 'letter')//995.28 es la altura, 1000.89 es lo ancho
    var scale = (doc.internal.pageSize.width - margin * 2) / document.querySelector("#mainBill").scrollWidth
    doc.html(document.querySelector("#mainBill"), {
      x: margin,
      y: margin,
      html2canvas: {
        scale: scale,
      },
      callback: function (pdf) {
        pdf.save("Factura.pdf");
        // setDisabledBottom(false)
        // setReload(!reload)

      }
    }
    )

  };

  const zoomOut = (event) => {
    // event.preventDefault();
    setScale(scale / 1.2); // Zoom out
  };

  const zoomIn = (event) => {
    // event.preventDefault();
    setScale(scale * 1.2); // Zoom in
  };

  return (
    <div className="mainBillPage">
      <div className="allBill" id="mainBill" style={{
      transform: `scale(${scale})`
      }}>
        <div className="logoBill">
          <img src="bank.png" alt="" />
        </div>

        <div className="titlesBill">
          <p>
            CUENTA DE COBRO No. {data.nro} <br />
            {/* <br /> */}
            {data.companyClient}
            <br /><br />
            {data.nitClient} <br />
            <br />
            DEBE A:
          </p>
        </div>

        <div className="contactInfoBill">
          <div className="titleContactBill">
            <li>Razon social:</li>
            <li>Tipo de identificacion:</li>
            <li>Documento:</li>
            <li>Dirección:</li>
            <li>Telefono:</li>
            <li>Ciudad y fecha:</li>
          </div>
          <div className="dataContactBill">
            <li>{data.company.split('_')[1]}</li>
            <li>{data.typedocument}</li>
            <li>{data.document}</li>
            <li>{data.address}</li>
            <li>{data.phone}</li>
            <li>
              {data.city} {data.date}
            </li>
          </div>
        </div>
        <div className="valuesTableBill">
          <table>
            <tr>
              <td>DETALLE</td>
              <td>CANTIDAD</td>
              <td>VALOR UNITARIO</td>
              {!data.billPage && <td>IVA</td>}
              <td>VALOR TOTAL</td>
            </tr>
            {
              data.items.map((item, index) => {
                let subTotalValue
                data.billPage ? subTotalValue = parseFloat(data.billPage ? item.cantidad : item.cantidadItem) * parseFloat(data.billPage ? item.valorUnit : item.precio)
                  : subTotalValue = parseFloat(data.billPage ? item.cantidad : item.cantidadItem) * parseFloat(data.billPage ? item.valorUnit : item.precio) * (1 + item.iva / 100);
                return (
                  <tr key={index}>
                    <td>{data.billPage ? item.detalle : item.producto}</td>
                    <td>{data.billPage ? item.cantidad : item.cantidadItem}</td>
                    <td>{data.billPage ? item.valorUnit : item.precio}</td>
                    {!data.billPage && <td>{item.iva}%</td>}
                    <td>{subTotalValue}</td>
                  </tr>
                );
              }, console.log("absoluteValue: ", totalValue))
            }
            {data.manure &&
              <tr>
                <td colspan={data.billPage ? "3" : "4"}>ABONO</td>
                <td>{data.manure}</td>
              </tr>
            }
            <tr>
              <td colspan={data.billPage ? "3" : "4"}>VALOR TOTAL</td>
              <td>{totalValue}</td>
            </tr>
          </table>
        </div>
        <div className="accountInfoBill">
          <p style={letterSpacing}>
            En letras: {priceToletter(totalValue)}
          </p>
          <div>Favor cancelar en la cuenta N° <strong style={letterSpacing}>{data.bankAccount} {data.bank}</strong></div>
        </div>

        <br />
        <br />
        <img src={urlSignature} alt="we can't find the signature" className='signatureBill' />
        <div className="lineFirmBill"></div>
        <div className="firmsBill">
          <p>FIRMA</p>
          <p>C.C {data.nitocc}</p>
          <p>CARGO: {data.positionCompany}</p>
        </div>
      </div>
      <div className='buttonContainerPdfTop'>
        <button className="buttonLeftZoom neon_Effect" onClick={zoomOut}><FaSearchMinus></FaSearchMinus></button>
        <button className="buttonRigthZoom neon_Effect" onClick={zoomIn}><FaSearchPlus></FaSearchPlus></button>
      </div>
      <div className="buttonContainerPdf">
        <NavLink to={data.billPage ? '/Facturas' : '/Cotizaciones'} className='Textregister'><button className="button neon_Effect" onClick={disableBill}><p>Atras</p></button></NavLink>
        <button className="button neon_Effect" onClick={createPDF}>Descargar</button>
      </div>
    </div>
  );
}

export default Contribution;
